import { createSlice } from "@reduxjs/toolkit";
import { Eventmanagement } from "../Actions/AdminActions";



export const evenetSlice = createSlice({
    name: "evenetSlice",
    initialState:{
        events: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(Eventmanagement.fulfilled, (state, action) => {
            state.events = action.payload.data.data;
            
        });
    }
})


export default evenetSlice.reducer;