import { createSlice } from "@reduxjs/toolkit";
import { NotificationListing } from "../Actions/AdminActions";

export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState:{
        allnotifications: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(NotificationListing.fulfilled, (state, action) => {
            state.allnotifications = action.payload.data.data;
            
        });
    }
})


export default notificationSlice.reducer;