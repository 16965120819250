import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import { useDispatch } from "react-redux";
import { getEventDetail } from "../redux/Actions/AdminActions";
import moment from "moment";
export default function EventView() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [open, setOpen] = useState(false);
  const [eventdata, setEventdata] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventDetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status == 200) {
          setEventdata(res?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);
  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/event-management")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Course view </h2>
        </div>

        <div className="course-view">
          <div className="left-view-section">
            <div className="outer-view">
              <div className="view-with-border">
                <div className="view-product">
                  {eventdata?.image ? (
                    <img src={`${url}${eventdata?.image}`} />
                  ) : (
                    "Image not found"
                  )}
                </div>
                <div className="about-this-product">
                  <h2>{eventdata?.title || "N/A"}</h2>
                </div>

                <div className="comp-certificate mt-3">
                  <p>Location - {eventdata?.address || "N/A"}</p>
                  <p>
                    Date - {moment(eventdata?.createdAt).format("DD/MM/YYYY - hh:mm A")  || "N/A"}

                  </p>
                </div>
                <div className="comp-certificate mt-2">
                  <p>
                    Price - <b> ${eventdata?.price || "00"}/per ticket </b>
                  </p>
                </div>

                <div className="we-learn">
                  <h2>Description</h2>
                  <p>
                    {eventdata?.desc || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
