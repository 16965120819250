import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Addnotification } from "../redux/Actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  send_to: Yup.string().required("Please select a user type"),
  title: Yup.string().trim().required("Required"),
  desc: Yup.string().trim().required("Required"),
});

export default function CreateNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const InitialValues = {
    send_to: "",
    title: "",
    desc: "",
  };

  const handleSubmit = async (values) => {
    try {
      const data = await dispatch(Addnotification(values));
      console.log(data);
      if (data?.payload?.status === 200) {
        toast.success(data?.payload?.message);
        navigate("/notifications");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error, "error occur");
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/notifications")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Create Notification </h2>
        </div>
        <Formik
          initialValues={InitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className="filters pb-2 pt-3">
                <div className="create-notification-page">
                  <div className="category-field">
                    <div className="input-select">
                      <Form.Group
                        className=""
                        controlId="postViewFormName"
                      >
                        <Form.Label>Select User</Form.Label>
                        <Form.Select
                          id="category"
                          type="text"
                          placeholder="Search"
                          className="select-option"
                          name="send_to"
                          value={values?.send_to}
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="0">Users</option>
                          <option value="1">Owners</option>
                          <option value="2">All</option>
                        </Form.Select>
                        <ErrorMessage
                          name="send_to"
                          component="div"
                          className="error-message"
                        />
                      </Form.Group>
                    </div>
                    <div className="modal-input ">
                      <label>Title</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here "
                          name="title"
                          value={values?.title}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="textarea-input">
                    <Form.Group className="mt-4">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type here"
                        name="desc"
                        value={values?.desc}
                        onChange={handleChange}
                      />
                       <ErrorMessage
                          name="desc"
                          component="div"
                          className="error-message"
                        />
                    </Form.Group>
                  </div>
                  <div className="create-btn">
                    <button type="submit">Create</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
