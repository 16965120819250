import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "./Reducer/UsermgmtSlice"
import { PromoSlice } from "./Reducer/PromoMgmtSlice";
import { shopsverifiactionSlice } from "./Reducer/ShopsverificationSlice";
import { shopsmanageSlice } from "./Reducer/Shopsmgmt";
import { shopsSlice } from "./Reducer/ShopsProductsSlice";
import { ordersmgmtSlice } from "./Reducer/OrderManagementSlice";
import { EducationSlice } from "./Reducer/EducationManageSlice";
import { evenetSlice } from "./Reducer/EventmgmtSlice";
import { FaqSlice } from "./Reducer/FaqMgmtSlice";
import { notificationSlice } from "./Reducer/NotificationSlice";

export const Store = configureStore({
  reducer: {
   usermgt:usermanageSlice.reducer,
   Promo:PromoSlice.reducer,
   shopsverifications:shopsverifiactionSlice.reducer,
   shopsmgmt:shopsmanageSlice.reducer,
   productShpos:shopsSlice.reducer,
   ordermgmt:ordersmgmtSlice.reducer,
   educationmgmt:EducationSlice.reducer,
   Event:evenetSlice.reducer,
   Faqdata:FaqSlice.reducer,
   notifications:notificationSlice.reducer
  },
});
