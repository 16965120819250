import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usermanagement } from '../redux/Actions/AdminActions';

const PaginationComponet = ({ currentPage, totalCount, limit, onPageChange }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(currentPage || 1); 


  const queryPage = new URLSearchParams(location.search).get('page') || 1;


  useEffect(() => {
    setPage(Number(queryPage)); 
    // dispatch(
    //   usermanagement({
    //     page: queryPage,
    //     limit: 10,
    //   })
    // );

}, [location.search, page, dispatch]);

const handlePageChange = (pageNumber) => {
  setPage(pageNumber);
  navigate(`/user?page=${pageNumber}`); 
  dispatch(
    usermanagement({
      page: pageNumber,
      limit: 10, 
    })
  );
};

  const pageItems = [];
  for (let number = 1; number <= limit; number++) {
    pageItems.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {pageItems}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === limit}
      />
      <Pagination.Last
        onClick={() => handlePageChange(limit)}
        disabled={currentPage === limit}
      />
    </Pagination>
  );
};

export default PaginationComponet;

