import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { DeleteFaq, FaqManagement, getAdmindetails } from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import moment from "moment";
import PaginationComponet from "../Components/Pagination";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Faq() {
  const data = useSelector((state) => state.Faqdata?.faqs);
  console.log(data);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [showd, setshowd] = useState(false);
  const [Id, setFaqID] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      FaqManagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(FaqManagement(searchData));
  }, [page, limit, search, flag]);

  const handledeletefaq = () => {
    dispatch(DeleteFaq({ id: Id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast?.success(res?.payload?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };


  const handlenavgate = (id) => {
    navigate(`/edit-faq/${id}`);
  };

  return (
    <Layout>
      <CommonHeader />
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>FAQ's</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="create-new-btn">
                  <button onClick={() => navigate("/add-faq")}>
                    Create New
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Create At</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.faqs?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>{dynamicIndex}</td>
                        <td>
                          <div className="user-id">
                            <p>{moment(res?.createdAt).format("DD/MM/YYYY") || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.question || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>{res?.answer || "N/A"}</p>
                          </div>
                        </td>

                        <td>
                          <div className="action user-verification">
                            <div>
                              <img
                              onClick={() => handlenavgate(res?._id)}
                                style={{ cursor: "pointer" }}
                                src={
                                  require("../Assets/Images/edit_icon.svg")
                                    .default
                                }
                              />
                            </div>

                            <img
                            onClick={() => {
                              setshowd(true);
                              setFaqID(res?._id);
                            }}
                              src={
                                require("../Assets/Images/white_delete_icon.svg")
                                  .default
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p> {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, data?.totalCount)} of{" "}
                    {data?.totalCount} results</p>
                </div>
                <div className="pagination-block">
                <PaginationComponet
                    currentPage={page}
                    totalCount={data?.totalCount}
                    limit={data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to delete this FAQ?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledeletefaq}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </Layout>
  );
}
