import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Error from "./Pages/Error";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import UserProfile from "./Pages/UserProfile";
import ShopsVerification from "./Pages/ShopsVerification";
import ShopsProfile from "./Pages/ShopsProfile";
import ShopsManagement from "./Pages/ShopsManagement";
import ShopsManagementProfile from "./Pages/ShopsManagementProfile";
import ShopsProducts from "./Pages/ShopProducts";
import ProductDetails from "./Pages/ProductDetails";
import ProductOverview from "./Pages/ProductOverview";
import OrderManagement from "./Pages/OrderManagement";
import OrderDetails from "./Pages/OrderDetails";
import ProductManagement from "./Pages/ProductManagement";
import AddCategory from "./Pages/AddCategory";
import AddWineCategory from "./Pages/AddWineCategory";
import AddWineBrand from "./Pages/AddWineBrand";
import CourseManagement from "./Pages/CourseManagement";
import Notifications from "./Pages/Notifications";
import CreateNotification from "./Pages/CreateNotification";
import Support from "./Pages/Support";
import ChatSupport from "./Pages/ChatSupport";
import CourseView from "./Pages/CourseView";
import EventManagement from "./Pages/EventManagement";
import EventView from "./Pages/EventView";
import Report from "./Pages/Report";
import ReportDetails from "./Pages/ReportDetails";
import ManagePromoCode from "./Pages/ManagePromoCode";
import AddPromoCode from "./Pages/AddPromoCode";
import EditPromoCode from "./Pages/EditPromoCode";
import Faq from "./Pages/Faq";
import AddFaq from "./Pages/AddFaq";
import EditFaq from "./Pages/EditFaq";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/otp-varification" element={<OtpVarification />}></Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="/error" element={<Error />}></Route>
            <Route
              path="/under-maintenance"
              element={<UnderMaintenance />}
            ></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route
              path="/user-verification"
              element={<UserVerification />}
            ></Route>
            <Route path="/user-profile/:id" element={<UserProfile />}></Route>
            <Route
              path="/shops-verification"
              element={<ShopsVerification />}
            ></Route>
            <Route path="/shops-profile/:id" element={<ShopsProfile />}></Route>
            <Route
              path="/shops-management"
              element={<ShopsManagement />}
            ></Route>
            <Route
              path="/shops-management-profile/:id"
              element={<ShopsManagementProfile />}
            ></Route>
            <Route
              path="/shops-products/:id"
              element={<ShopsProducts />}
            ></Route>
            <Route
              path="/product-details/:id"
              element={<ProductDetails />}
            ></Route>
            <Route
              path="/product-overview/:id"
              element={<ProductOverview />}
            ></Route>
            <Route
              path="/order-management"
              element={<OrderManagement />}
            ></Route>
            <Route path="/order-details/:id" element={<OrderDetails />}></Route>
            <Route
              path="/product-management"
              element={<ProductManagement />}
            ></Route>
            <Route path="/add-category" element={<AddCategory />}></Route>
            <Route
              path="/add-wine-category"
              element={<AddWineCategory />}
            ></Route>
            <Route path="/add-wine-brand" element={<AddWineBrand />}></Route>
            <Route
              path="/course-management"
              element={<CourseManagement />}
            ></Route>
            <Route path="/Notifications" element={<Notifications />}></Route>
            <Route
              path="/create-notification"
              element={<CreateNotification />}
            ></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/chat-support" element={<ChatSupport />}></Route>
            <Route path="/course-view/:id" element={<CourseView />}></Route>
            <Route
              path="/event-management"
              element={<EventManagement />}
            ></Route>
            <Route path="/event-view/:id" element={<EventView />}></Route>
            <Route path="/report" element={<Report />}></Route>
            <Route path="/report-details" element={<ReportDetails />}></Route>
            <Route
              path="/manage-promo-code"
              element={<ManagePromoCode />}
            ></Route>
            <Route path="/add-promo-code" element={<AddPromoCode />}></Route>
            <Route
              path="/edit-promo-code/:id"
              element={<EditPromoCode />}
            ></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/add-faq" element={<AddFaq />}></Route>
            <Route path="/edit-faq/:id" element={<EditFaq />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
