import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addFaqs } from "../redux/Actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

export default function AddFaq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Initialvalues = {
    question: "",
    answer: "",
  };

  const handleaddfaq = (values) => {
    dispatch(addFaqs(values)).then((res) => {
      console.log(res);
      if(res?.payload?.status == 200){
        toast.success(res?.payload?.message)
        navigate("/faq");
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div className="arrow-alt-back" onClick={() => navigate("/faq")}>
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Add FAQ </h2>
        </div>
        <div className="filters pb-2 pt-3">
          <Formik
            initialValues={Initialvalues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleaddfaq(values)}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="create-notification-page">
                  <div className="category-field">
                    <div className="modal-input ">
                      <label>Add Question</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here "
                          name="question"
                          value={values?.question}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="question"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="textarea-input">
                    <Form.Group className="mt-4">
                      <Form.Label>Add Answer</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type here"
                        name="answer"
                        value={values?.answer}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="answer"
                        className="error-message"
                        component="div"
                      />
                    </Form.Group>
                  </div>
                  <div className="create-btn">
                    <button type="submit">Create</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
