import { createSlice } from "@reduxjs/toolkit";
import { FaqManagement } from "../Actions/AdminActions";

export const FaqSlice = createSlice({
    name: "FaqSlice",
    initialState:{
        faqs: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(FaqManagement.fulfilled, (state, action) => {
            state.faqs = action.payload.data.data;
            
        });
    }
})


export default FaqSlice.reducer;