import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import { getCourseDetail } from "../redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
export default function CourseView() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [open, setOpen] = useState(false);
  const [CourseData, setcoursedata] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourseDetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status == 200) {
          setcoursedata(res?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

    

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/course-management")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Course view </h2>
        </div>

        <div className="course-view">
          <div className="left-view-section">
            <div className="outer-view">
              {CourseData?.chapters?.map((res) => {
                console.log(res);
                return(
              
              <div className="view-with-border">
                <div className="view-product">
                 {res?.banner_image ? (
                  <img src={`${url}${res?.banner_image}`}/>
                 ): "Image not found"}
                </div>
                <div className="about-this-product">
                  <h2>About this course</h2>
                </div>

                <div className="about-over-view">
                  <h2>Overview</h2>
                </div>
                <div className="comp-certificate">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/check-icon-gray.svg")
                            .default
                        }
                      />
                    </span>
                    Complete Certificate
                  </p>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/watch-icon.svg").default}
                      />
                    </span>
                    6 Hours
                  </p>
                </div>
                <div className="comp-certificate mt-2">
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/check-icon-gray.svg")
                            .default
                        }
                      />
                    </span>
                    Quizzes
                  </p>
                </div>

                <div className="we-learn">
                  <h2>What will i learn?</h2>
                  <p>
                    he material of this course is also covered in my other
                    course about web design and development with HTML5 & CSS3.
                    Scroll to the bottom of this page to check out that course,
                    too! If you're already taking my other course, you already
                    have all it takes to start designing beautiful websites
                    today!
                  </p>
                </div>
              </div>
                )
              })}
            </div>

            <div className="outer-view mt-4">
              <div className="view-with-border">
                <div className="view-product">
                  <img src={require("../Assets/Images/product-image.png")} />
                </div>

                <div className="about-over-view">
                  <h2>Course Introduction</h2>
                </div>

                <div className="we-learn mt-0">
                  <p>
                    he material of this course is also covered in my other
                    course about web design and development with HTML5 & CSS3.
                    Scroll to the bottom of this page to check out that course,
                    too! If you're already taking my other course, you already
                    have all it takes to start designing beautiful websites
                    today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="question-ans">
            <div className="first-question-coll">
              <div className="collapse-section ">
                <div
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <div className="collapse-tab">
                    <div className="collapse-content">
                      <p> Question 1 </p>
                      <h3>What does Merlo Stand for?</h3>
                    </div>
                    <div className="collapse-icon">
                      <img
                        src={
                          require("../Assets/Images/chevron-down.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="ans-options">
                      <ul>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section ">
                <div
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <div className="collapse-tab">
                    <div className="collapse-content">
                      <p> Question 2 </p>
                      <h3>What does Merlo Stand for?</h3>
                    </div>
                    <div className="collapse-icon">
                      <img
                        src={
                          require("../Assets/Images/chevron-down.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="ans-options">
                      <ul>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>
              <div className="collapse-section ">
                <div
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <div className="collapse-tab">
                    <div className="collapse-content">
                      <p> Question 3 </p>
                      <h3>What does Merlo Stand for?</h3>
                    </div>
                    <div className="collapse-icon">
                      <img
                        src={
                          require("../Assets/Images/chevron-down.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="ans-options">
                      <ul>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className="collapse-section ">
                <div
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <div className="collapse-tab">
                    <div className="collapse-content">
                      <p> Question 4 </p>
                      <h3>What does Merlo Stand for?</h3>
                    </div>
                    <div className="collapse-icon">
                      <img
                        src={
                          require("../Assets/Images/chevron-down.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="ans-options">
                      <ul>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                        <li>
                          <div className="check-option">
                            <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div>
                            <p>1. Welcome to this course</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
